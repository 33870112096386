export default {
  'app_ame': 'NoteForms',
  'locale': 'en',
  'locales': { 'en': 'EN' },
  'githubAuth': null,
  'notion': {
    'worker': 'https://notion-forms-worker.notionforms.workers.dev/v1',
    'unsupported_form_types': [
      'formula',
      'rollup',
      'created_time',
      'created_by',
      'last_edited_time',
      'last_edited_by',
      'unique_id'
    ],
    'unsupported_view_types': [
      'created_time',
      'created_by',
      'last_edited_time',
      'last_edited_by',
      'unique_id'
    ],
    'database_filterable_types': [
      'title',
      'rich_text',
      'url',
      'email',
      'phone_number',
      'number',
      'checkbox',
      'select',
      'multi_select',
      'status',
      'date',
      'people',
      'relation'
    ]
  },
  'links': {
    'help_url': 'https://help.noteforms.com/',
    'facebook_group': 'https://www.facebook.com/groups/notionforms/',
    'twitter': 'https://twitter.com/NotionForms',
    'status': 'https://status.notionforms.io/',
    'zapier_integration': 'https://zapier.com/developer/public-invite/146950/58db583730cc46b821614468d94c35de/',
    'book_onboarding': 'https://zcal.co/i/YQVGEULQ',
    'testimonial': 'https://testimonial.to/noteforms/all',
    'helpdesk_sitemap_url': 'https://notionforms.crisp.help/sitemap.xml',
    'changelog_url': 'https://feedback.noteforms.com/changelog',
    'feature_requests': 'https://feedback.noteforms.com/'
  },
  'production': false
}
